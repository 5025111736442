import React, { useState } from 'react';
import {Link, Navigate} from 'react-router-dom';
import '../CSSStyling/Admin/viewDatabase.css';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
function ViewProducts() {
    const [searchOption, setSearchOption] = useState('product_id');
    const [productID, setProductID] = useState("");
    const [productType, setProductType] = useState("");
    const [tableHeaders, setTableHeaders] = useState(["product_name", "product_id", "images", "product_category", "product_type"]);
    const [productData, setProductData] = useState(
        {
            "product-name": "",
            "product-id": "",
            "product-image": "",
            "product-category": "",
            "product-type": "",
        }
    );
    const [productTypeData, setProductTypeData] = useState([]);
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (searchOption === 'product_id') {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/search/${productID}`);
            const data = await response.json();
            if (data.productData) {
                await setProductData({
                    "product-name": data.productData.product_name,
                    "product-id": data.productData._id,
                    "product-image": data.productData.images,
                    "product-category": data.productData.product_category,
                    "product-type": data.productData.product_type,
                });
            }
            else {
                await setProductData({
                    "product-name": "",
                    "product-category": "",
                    "product-id": "",
                    "product-type": "",
                    "product-image": "",
                });
                alert("No such product found in the database!!");
            }

        }
        else if (searchOption === 'product_type') {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/search/type/${productType}`);
            const data = await response.json();
            await setProductID("");
            if (data.productData.length > 0) {
                const newData = data.productData.map(product => {
                    const filterData = {};

                    tableHeaders.forEach((header) => {
                        filterData[header] = product[header];
                    })

                    return filterData;
                })
                await setProductTypeData(newData);

            }
        }
    }
    const handleSearchOptionChange = async (option) => {
        await setSearchOption(option);
        if (option === "product_type")
            await setProductID("");
        else
            await setProductTypeData("");
    };
    const handleDeleteProduct = async (product_id, product_type) => {
        const result = window.confirm("Delete this product from the database?");
        if (result === true) {
            if (productID !== "") {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/deleteproduct/${product_type}/${product_id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                const data = await response.json();
                if (data.message == "ok") {
                    await setProductID("");
                    await setProductData({
                        "product-name": "",
                        "product-category": "",
                        "product-id": "",
                        "product-type": "",
                        "product-image": "",
                    });
                    alert("Product deleted successfully!!");
                }
            }
            else {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/deleteproduct/${product_type}/${product_id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                const data = await response.json();
                if (data.message == "ok") {
                    await setProductID("");
                    alert("Product deleted successfully!!");
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/search/type/${productType}`);
                    const data = await response.json();
                    if (data.productData.length > 0) {
                        const newData = data.productData.map(product => {
                            const filterData = {};

                            tableHeaders.forEach((header) => {
                                filterData[header] = product[header];
                            })

                            return filterData;
                        })
                        await setProductTypeData(newData);
                    }
                }
            }
        }

    }
    return (
        <><div className='admin-page-navbar'>
        <div className='admin-page-heading'>Welcome FinnoFarms Admin</div>
        <button 
        className='login-btn-admin'
        onClick={() => {
            Navigate('/admin/chat-to-customers')
        }}
        >
            Admin Chat
        </button>
        <Link to='/admin'>
            <button className='login-btn-admin'> 
                Home
            </button>
        </Link>
        <button className='login-btn-admin' onClick={()=>{
        alert("Log out successful!!");
        localStorage.removeItem("adminToken");
        Navigate('/admin/login');
        }}>
            LOG OUT
        </button>
    </div>
        <div className='search-form-container'>
        
            <h2>Search Product</h2>
            <div className='search-options'>
                <button className={searchOption === 'product_id' ? 'active' : ''} onClick={() => handleSearchOptionChange('product_id')}>Search by Product ID</button>
                <button className={searchOption === 'product_type' ? 'active' : ''} onClick={() => handleSearchOptionChange('product_type')}>Search by Type</button>
            </div>

            {searchOption === 'product_id' ? (
                <form className='product-search-form' onSubmit={handleFormSubmit}>
                    <div className='search-form-group'>
                        <input type="text" name='product_id' className='search-form-input' placeholder='Product ID' value={productID} onChange={(e) => {
                            setProductID(e.target.value);
                            setProductType("");
                        }} />
                    </div>
                    <button type='submit' className='search-product-btn' >Search Product</button>
                </form>
            ) : (
                <form className='product-search-form' onSubmit={handleFormSubmit}>
                    <div className='search-form-group'>
                        <select name='product_type' className='search-form-input' value={productType} onChange={(e) => {
                            setProductID("");
                            setProductType(e.target.value);
                        }}>
                            <option value='null'>-:Select your option:-</option>
                            <option value='Fruit'>Fruit</option>
                            <option value='Vegetable'>Vegetable</option>
                            <option value='Freshwater Fish'>Freshwater Fish</option>
                            <option value='Seawater Fish'>Seawater Fish</option>
                            <option value='Shell Fish'>Shellfish</option>
                            <option value='FinnoSpecial'>Finnofarms Special</option>
                        </select>
                    </div>
                    <button type='submit' className='search-product-btn' >Search Product</button>
                </form>
            )}

            {((productType === "") && (productID !== "")) ? (
                <div>
                    <h2>Product Details</h2>
                    <table className='product-table'>
                        <thead>
                            <tr>
                                {Object.keys(productData).map((key) => (
                                    <th key={key}>{key.toLocaleUpperCase()}</th>
                                ))}
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {Object.values(productData).map((value, index) => {
                                    return (
                                        <td key={index}>
                                            {index === 2 ? (
                                                <img src={value} alt="Product Image" height={70} width={70} />
                                            ) : (
                                                value
                                            )}
                                        </td>
                                    );
                                })}
                                <td>
                                    <button className='product-edit-btn'><Link className='editProductLink' target={'_blank'} to={`/admin/editproduct/${productData['product-id']}`} >Edit <EditIcon /></Link></button>
                                </td>
                                <td>
                                    <button className='product-delete-btn' onClick={(e) => {
                                        handleDeleteProduct(productData['product-id'], productData['product-type']);
                                    }}>Delete <DeleteForeverIcon /></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    {((productID === "") && (productType !== "") && (productTypeData.length > 0)) ? (<div>
                        <h2>Product Details</h2>
                        <table className='product-table'>
                            <thead>
                                <tr>
                                    {Object.keys(productData).map((key) => (
                                        <th key={key}>{key.toLocaleUpperCase()}</th>
                                    ))}
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {productTypeData.map((product, index) => {
                                    return (
                                        <tr key={index}>
                                            {Object.entries(product).map(([key, value]) =>
                                            (
                                                <td key={key}>
                                                    {key === "images" ? (
                                                        <img src={value} alt="Product Image" height={70} width={70} />
                                                    ) : (
                                                        value
                                                    )}
                                                </td>

                                            ))}
                                            <td>
                                                <button className='product-edit-btn'><Link className='editProductLink' target={'_blank'} to={`/admin/editproduct/${product.product_id}`}>Edit <EditIcon /></Link></button>
                                            </td>
                                            <td> <button className='product-delete-btn' value={product.product_id} onClick={(e) => {
                                                handleDeleteProduct(e.target.value, product.product_type);
                                            }}>Delete <DeleteForeverIcon /></button></td>
                                        </tr>
                                    );
                                })}


                            </tbody>
                        </table>
                    </div>) :
                        (<div></div>)} </div>
            )}
        </div></>
    )
}

export default ViewProducts